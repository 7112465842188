export default {
    name: 'Search',

    // 路由进入
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.keyword = to.query.keyword || '';
            vm.handleGetData(1);
        })
    },
    // 路由更新
    beforeRouteUpdate (to, from, next) {
        this.keyword = to.query.keyword || '';
        next()
        this.handleGetData(1);
    },

    mounted () {
        this.items.forEach(v => {

        })
    },

    methods: {
        // 获取档案记录列表
        handleGetData (page = 1) {
            if (this.keyword == '') return this.items = [];
            if (this.loading) return;
            this.loading = true;
            this.$Message.loading({content: '数据请求中...', duration: 0})
            this.$api.archives.archivesList({
                keyword: this.keyword,
                page: page,
                page_number: 10
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.obj = res.obj;
                res.list.forEach(v => {
                    v.archives_name = v.archives_name.replace(this.keyword, `<span>${this.keyword}</span>`)
                });
                this.items = res.list;
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        }
    },

    data () {
        return {
            loading: false,
            keyword: '',
            obj: {},
            items: [],
            columns: [
                {
                    title: '档案名称',
                    slot: 'title',
                    minWidth: 300
                },
                {
                    title: '档案编号',
                    slot: 'code',
                    width: 160
                },
                {
                    title: '档案类别',
                    slot: 'type',
                    width: 260
                },
                {
                    title: '文件目录',
                    slot: 'material_list',
                    width: 330
                },
            ],
            roleStaff:JSON.parse(localStorage.getItem('staff_auth_info')),
        }
    }
}