var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-container"},[_c('div',{staticClass:"layout-header"},[_c('router-link',{staticClass:"back",attrs:{"to":{path: decodeURIComponent(_vm.$route.query.fullPath)}}},[_c('Icon',{attrs:{"type":"ios-arrow-back","size":"22","color":"#343434"}}),_vm._v(" "+_vm._s(_vm.$route.query.title))],1),_c('div',{staticClass:"ctr clearfix"},[_c('div',{staticClass:"search"},[(_vm.roleStaff['archive/report']!=0)?_c('Input',{attrs:{"prefix":"md-search","clearable":"","placeholder":"文件名/姓名"},on:{"on-enter":function($event){return _vm.handleGetData(1)},"on-clear":function($event){return _vm.handleGetData(1)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}):_vm._e(),_c('Button',{attrs:{"type":"primary","to":{name: 'Search', query: {title: '档案详情', fullPath: encodeURIComponent(_vm.$route.fullPath), keyword: _vm.keyword}},"icon":"md-search"}},[_vm._v("搜索")])],1)])],1),_c('div',{staticClass:"layout-body"},[(_vm.items.length > 0)?_c('div',{staticClass:"hd"},[_vm._v("关于“"+_vm._s(_vm.keyword)+"”的搜索结果，共"+_vm._s(_vm.obj.total)+"条")]):_vm._e(),_c('Table',{staticClass:"table",attrs:{"columns":_vm.columns,"data":_vm.items},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"table-text",attrs:{"to":{name: 'RetrievalDetail', query: {code: row.archives_code, type: row.archive_type}},"title":row.archives_name},domProps:{"innerHTML":_vm._s(row.archives_name)}})]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(row.archives_first_category)+" >> "+_vm._s(row.archives_second_category))])]}},{key:"code",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(row.archives_code))])]}},{key:"material_list",fn:function(ref){
var row = ref.row;
return [_c('Poptip',{attrs:{"trigger":"hover"}},[_c('div',{staticClass:"table-text row1"},[_vm._v(_vm._s(row.material_list))]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(row.material_list))])])]}}])}),(_vm.items.length > 0)?_c('div',{staticClass:"layout-page tc"},[_c('Page',{attrs:{"total":_vm.obj.total,"size":"small","show-total":""},on:{"on-change":function($event){return _vm.handleGetData(arguments[0])}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }